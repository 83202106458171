<template>
  <div id="calendar-id" class="calendar" style="overflow: scroll;">
  <button :style="{ marginLeft: '90%', marginBottom: '10px' }" class="btn btn-primary" @click="toggleRedEvents">
  {{ redEventsVisible ? "Hide Red Events" : "Show Red Events" }}
</button>

    <FullCalendar
      v-if="!this.showEvents"
      :key="this.calendarKey"
      :options="calendarOptions"
      :ref="this.calendarRef"
    />
    <div v-if="this.showEvents">
      <h2 class="text-right" ref="sbodScroll">
        <button
          type="button"
          class="bg-green-300 rounded-full font-semibold py-2 mt-4 mb-4 mr-4 text-center px-4"
          @click="showCal"
        >
          Close
        </button>
        <div class="form-group">
          <input type="radio" name="filterDay" v-model="filterDay" value="1" />
          All
          <input type="radio" name="filterDay" v-model="filterDay" value="0" />
          {{ this.showEventsDate }}
        </div>
      </h2>
      <table id="" class="border-collapse w-full text-sm">
        <thead>
          <tr>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Event Date
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              TM Link
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              TP Link
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Venue Name
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Notes
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Password
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Venue Capacity
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Currency
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Min
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Max
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Ticket Limit
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Sales
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Attractions
            </th>
            <th
              class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
            >
              Seat Map
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="!priceRange"
            class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          >
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
              colspan="5"
            >
              Please wait...
            </td>
          </tr>
          <tr
            v-for="(item, i) in filteredDay"
            :key="i"
            class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          >
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              {{ item.date }}
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              <a
                class="cursor-pointer text-blue-600"
                :href="item.url"
                target="_blank"
                ><img
                  src="https://spaceseats.io/img/ticket.9586a5df.jpeg"
                  alt="img"
                  style="max-width: 30px;"
              /></a>
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              <a
                class="cursor-pointer text-blue-600"
                :href="'https://www.tickpick.com/search?q=' + item.ename"
                target="_blank"
                ><img
                  src="@/assets/img/landing/tickpick.png"
                  alt="img"
                  style="max-width: 30px;"
              /></a>
            </td>
            <td
              class="p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
              style="width: 15%"
            >
              {{ item.vname }}
            </td>

            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              <p v-if="item.isNotesEditing"></p>
              <p v-if="!item.isNotesEditing">{{ item.notes }}</p>
              <div v-else-if="item.isNotesEditing">
                <textarea
                  placeholder="type your notes here"
                  v-model="notesInput"
                  class="input-styling"
                  rows="4"
                  cols="40"
                >
                </textarea>
              </div>
              <button
                class="text-green-600"
                @click="editNotes(i)"
                v-if="!item.isNotesEditing"
              >
                Edit
              </button>
              <button
                class="text-green-600"
                @click="saveNotes(i)"
                v-else-if="item.isNotesEditing"
              >
                Save
              </button>
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              <p v-if="item.isPasswordEditing"></p>
              <p v-if="!item.isPasswordEditing">{{ item.password }}</p>
              <div v-else-if="item.isPasswordEditing">
                <input
                  placeholder="type your notes here"
                  v-model="passwordInput"
                  class="input-styling"
                />
              </div>
              <button
                class="text-green-600"
                @click="editPassword(i)"
                v-if="!item.isPasswordEditing"
              >
                Edit
              </button>
              <button
                class="text-green-600"
                @click="savePassword(i)"
                v-else-if="item.isPasswordEditing"
              >
                Save
              </button>
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              <div
                style="cursor: pointer"
                class="text-green-800"
                :id="'vid' + i"
              >
                <div @click="getCapacity('vid' + i, item.vname + ' Capacity')">
                  Fetch
                </div>
              </div>
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              {{ item.currency }}
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              {{ item.min }}
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              {{ item.max }}
            </td>
            <td
              class="w-full lg:w-auto p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
            >
              {{ item.tl }}
            </td>
            <td
              class="p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
            >
              <p><b>Public:</b></p>
              <p>&nbsp;Start: {{ item.salesPuStart }}</p>
              <p><b>Presales:</b></p>
              <div v-if="item.presales">
                <div v-for="(p, i) in item.presales" :key="i">
                  <u>&nbsp;{{ p.name }}:</u>
                  <p>&nbsp;&nbsp;Start: {{ p.start }}</p>
                </div>
              </div>
            </td>
            <td
              class="p-3 text-gray-800 border border-b block md:table-cell relative lg:static"
            >
              <div
                v-for="(a, i) in item.attractions"
                :key="i"
                :id="'keywordTextDiv' + i"
              >
                <p>&nbsp;{{ a.name }}</p>
              </div>

              <div
                style="cursor: pointer"
                x-data="{ item.pop: false }"
                class="text-green-800 relative"
                type="button"
                @click="
                  item.pop = !item.pop;
                  getKeywordStatsModal(
                  
                    i,
                    item.attractions
                  );
                "
              >
                Fetch Stats
               
              </div>
              

              <div
                class="overflow-y-auto overflow-x-hidden mx-auto my-auto w-50 h-50 bg-white fixed z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] shadow-2xl max-h-full bg-gray-800 bg-opacity-50"
                :id="'keywordsStatsInner' + i"
                v-show="item.pop"
                x-transition:enter="transition ease-out duration-100 transform"
                x-transition:enter-start="opacity-0 scale-95"
                x-transition:enter-end="opacity-100 scale-100"
                x-transition:leave="transition ease-in duration-75 transform"
              >
                <div
                  class="relative text-center w-100 font-bold px-3 py-10 bg-gray-100 bg-opacity-50 flex justify-center items-center "
                  :id="'stats-modal-header' + i"
                >
                  <h2 class="text-2xl">Keyword Statistics from Google</h2>

                  <button
                    class="absolute bg-green-300 rounded-full font-semibold py-2 right-2 text-center px-4"
                    @click="item.pop = false"
                    :id="'close-modal' + i"
                  >
                    Close
                  </button>
                </div>
                <div
                  class="relative px-10 my-10 "
                  :id="'stats-modal-content' + i"
                >
                  <p class="text-green-300">
                    Fetching the data may take 5-10 seconds ....
                  </p>
                </div>
              </div>
            </td>

            <td
              class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
            >
              <img
                :src="item.seatmap"
                @click="showImage(item.seatmap)"
                width="150px"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridWeek from "@fullcalendar/timegrid";
import multiMonth from "@fullcalendar/multimonth";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import "viewerjs/dist/viewer.css";
import axios from "axios";
import { BPopover } from "bootstrap-vue";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";

Vue.use(BootstrapVue);

export default {
  name: "Calendar",
  components: {
    FullCalendar,
  },
  data() {
    return {
      showEvents: false,
      calendarKey: 0,
      calendarRef: "myCalendar",
      showEventsDate: "",
      filterDay: "0",
      notesInput: "",
      passwordInput: "",
      currentEventId: "",
      events: [],
      priceRange: [],
      comma_seperated_keywords: [],
      content_new: "",
      eData: {},
      redEventsVisible: false, // Hide red events by default
      colorFilter: "all", // State variable for color filtering
    };
  },
  computed: {
    filteredDay() {
      let th = this;
      if (this.filterDay == "1") {
        return this.priceRange;
      } else {
        return this.priceRange.filter(function (pr) {
          let check = false;
          for (let j = 0; j < pr.presales.length; j++) {
            if (th.showEventsDate === pr.presales[j].start) check = true;
          }
          return check;
        });
      }
    },
    filteredEvents() {
      return this.events.filter(event => {
        if (!this.redEventsVisible && event.color === "red") {
          return false;
        }
        if (this.colorFilter === "all") {
          return true;
        }
        return event.color === this.colorFilter;
      });
    },
    calendarOptions() {
      return {
        plugins: [
          momentTimezonePlugin,
          dayGridPlugin,
          timeGridWeek,
          multiMonth,
        ],
        timeZone: "UTC",
        headerToolbar: {
          left: "dayGridMonth,timeGridWeek,timeGridDay",
          center: "title",
          right: "today prev,next",
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,

        eventDidMount: (info) => {
          this.customizeEventRendering(info);

          this.content_new = (
            <div>
              <p style="font-size: 15px;">
                {info.event.extendedProps.description}
              </p>
              <br />
              <div>
                <a target="_blank" href={info.event.extendedProps.tlink}>
                  <img
                    src="https://spaceseats.io/img/ticket.9586a5df.jpeg"
                    alt="img"
                    style="max-width: 30px;"
                  ></img>
                </a>
              </div>
            </div>
          );
          new BPopover({
           propsData: {
              target: info.el,
              title: "Information",
              boundary: "app",
             
              content: this.content_new,
              sanitize: false,
              triggers: "hover",
              placement: "auto",
              html: true,
              boundaryPadding: 5,
              delay: 500,
              offset: 0,
            },
          }).$mount();
        },

        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,

        events: this.filteredEvents,
      };
    },
  },
  methods: {
    toggleRedEvents() {
      this.redEventsVisible = !this.redEventsVisible;
      this.$refs.calendarRef.getApi().rerenderEvents();
    },
    filterByColor(color) {
      this.colorFilter = color;
      this.$refs.calendarRef.getApi().rerenderEvents();
    },
    addStringBeforeSpecific(pos, originalString, newString) {
      let originalText = originalString;
      let comingText = newString;

      let originalArray = originalText.split(", ");

      originalArray.splice(pos, 0, comingText);

      let resultText = originalArray.join(", ");

      return resultText;
    },
    fetchEvents() {
      axios
        .get("https://varilytics.com/api/calendar_new")
        .then((response) => {
          var thi = [];

          for (let i = 0; i < response.data.data.length; i++) {
            let title = this.addStringBeforeSpecific(
              4,
              response.data.data[i].event_name,
              response.data.data[i].sale_type
            );

            thi.push({
              title: title,
              description: title,
              tlink: response.data.data[i].tmurl,

              start:
                response.data.data[i].edate != ""
                  ? response.data.data[i].edate
                  : response.data.data[i].date.replaceAll("/", "-"),
              id: response.data.data[i].fav_id,
              groupId: response.data.data[i].fav_id,
              data: response.data.data[i].events,
              thumb: "https://spaceseats.io/img/ticket.9586a5df.jpeg",
              color: response.data.data[i].fav_type,
              textColor:
                response.data.data[i].fav_type == "yellow" ? "black" : "white",
            });
          }

          this.events = this.filterUniqueArray(thi);
          this.calendarKey = this.events.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    customizeEventRendering(info) {
      info.el.style.backgroundColor = info.event.extendedProps.color; // Set the background color
      info.el.style.borderColor = info.event.extendedProps.color; // Set the border color

      if (info.event.extendedProps.color === "red" && !this.redEventsVisible) {
        info.el.style.display = "none"; // Hide red events if redEventsVisible is false
      }
    },
    filterUniqueArray(arr) {
      const uniqueItems = []; // Create a new array for the unique objects
      const seen = new Set(); // Create a Set to store unique combinations of title and type

      for (const item of arr) {
        const key = `${item.title}-${item.start}`; // Create a unique key based on title and type

        if (!seen.has(key)) {
          seen.add(key);
          uniqueItems.push(item);
        }
      }

      return uniqueItems;
    },
    editNotes(i) {
      this.priceRange[i].isNotesEditing = !this.priceRange[i].isNotesEditing;
      this.notesInput = this.priceRange[i].notes;
    },
    saveNotes(i) {
      this.priceRange[i].notes = this.notesInput;
      this.priceRange[i].isNotesEditing = !this.priceRange[i].isNotesEditing;
      var foundIndex = this.eData.findIndex(
        (x) => x.id == this.priceRange[i].id
      );
      this.eData[foundIndex].notes = this.priceRange[i].notes;
      axios
        .post(`https://varilytics.com/api/updatefavourite_events_new`, {
          fav_id: this.currentEventId,
          events: this.eData,
          title: "Notes",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((result) => {
          alert(result.data.message);
        })
        .catch((error) => console.log("error", error));
    },
    editPassword(i) {
      this.priceRange[i].isPasswordEditing = !this.priceRange[i]
        .isPasswordEditing;
      this.passwordInput = this.priceRange[i].password;
    },
    savePassword(i) {
      this.priceRange[i].password = this.passwordInput;
      this.priceRange[i].isPasswordEditing = !this.priceRange[i]
        .isPasswordEditing;
      var foundIndex = this.eData.findIndex(
        (x) => x.id == this.priceRange[i].id
      );
      this.eData[foundIndex].password = this.priceRange[i].password;
      axios
        .post(`https://varilytics.com/api/updatefavourite_events_new`, {
          fav_id: this.currentEventId,
          events: this.eData,
          title: "Password",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((result) => {
          alert(result.data.message);
        })
        .catch((error) => console.log("error", error));
    },
    showCal() {
      this.showEvents = false;
    },

    handleEventClick(clickInfo) {
      this.showEventsDate = new Date(clickInfo.event.start).toLocaleString(
        "en-US",
        {
          timeZone: "UTC",
        }
      );

      console.log(clickInfo.event.id);
      this.priceRange = [];
      if (clickInfo.event.id) {
        this.currentEventId = clickInfo.event.id;
        axios
          .get(
            "https://varilytics.com/api/getfavourite_events_new?eid=" +
              clickInfo.event.id
          )
          .then((response) => {
            console.log(response);
            this.eData = JSON.parse(response.data.data.events);

            console.log(this.eData);
            for (let j = 0; j < this.eData.length; j++) {
              if (this.eData[j].priceRanges && this.eData[j]._embedded) {
               

                let tlim = "N/A";
                if (this.eData[j].ticketLimit)
                  tlim = this.eData[j].ticketLimit.info;
                let smap = "https://spaceseats.io/img/notfound.png";
                if (this.eData[j].seatmap)
                  smap = this.eData[j].seatmap.staticUrl;

                let preSales = [];

                let attractionsData = [];

                if (this.eData[j].sales.presales) {
                  for (
                    let p = 0;
                    p < this.eData[j].sales.presales.length;
                    p++
                  ) {
                    preSales.push({
                      name: this.eData[j].sales.presales[p].name,
                      start: new Date(
                        this.eData[j].sales.presales[p].startDateTime
                      ).toLocaleString("en-US", {
                        timeZone: "UTC",
                      }),
                    });
                  }
                }
                if (this.eData[j]._embedded.attractions) {
                  for (
                    let a = 0;
                    a < this.eData[j]._embedded.attractions.length;
                    a++
                  ) {
                    attractionsData.push({
                      name: this.eData[j]._embedded.attractions[a].name,
                      
                    });
                  }
                }

                this.priceRange.push({
                  date: new Date(
                    this.eData[j].dates.start.localDate
                  ).toLocaleString("en-US", { timeZone: "UTC" }),
                  vname: this.eData[j]._embedded.venues[0].name,
                  ename: this.eData[j].name,
                  url: this.eData[j].url,
                  currency: this.eData[j].priceRanges[0].currency,
                  min: this.eData[j].priceRanges[0].min,
                  max: this.eData[j].priceRanges[0].max,
                  tl: tlim,
                  salesPuStart: new Date(
                    this.eData[j].sales.public.startDateTime
                  ).toLocaleString("en-US", { timeZone: "UTC" }),
                  presales: preSales,
                  attractions: attractionsData,
                  pop: false,
                  notes:
                    this.eData[j].notes ??
                    "Price Range: " +
                      this.eData[j].priceRanges[0].min +
                      " - " +
                      this.eData[j].priceRanges[0].max +
                      " \nTicket Limit: " +
                      tlim +
                      " \nLink: " +
                      this.eData[j].url +
                      "\n \n",
                  password: this.eData[j].password ?? "Password",
                  isNotesEditing: false,
                  isPasswordEditing: false,
                  seatmap: smap,
                  id: this.eData[j].id,
                });
              }
            }
           
          })
          .catch((error) => {
            console.log(error);
          });
      }
      
      this.showEvents = true;
     
      this.currentEventName = clickInfo.event.name;
    
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
     ContainsAny(str, items) {
      for (var i in items) {
        var item = items[i];
        if (str.indexOf(item) > -1) {
          return true;
        }
      }
      return false;
    },
    getCapacity(vid, qury) {
      document.getElementById(vid).innerHTML = "Fetching...";

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://en.wikipedia.org/w/api.php?action=query&list=search&prop=info&inprop=url&utf8=&format=json&origin=*&srsearch=${qury}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          let eCheck = false;
          // var html = "<html>" + result.query?.search[0]?.snippet + "</html>";
          var html = "<html>";

          result.query?.search.forEach((element) => {
            if (
              this.ContainsAny(element.snippet, [
                "seat",
                "seats",
                "capacity",
                "seating",
              ])
            ) {
              html += element.snippet;
              eCheck = true;
            }
          });
          html += "</html>";
          if (eCheck) {
            document.getElementById(vid).innerHTML = html;
          } else {
            document.getElementById(vid).innerHTML =
              "<html> Not Found! </html>";
          }
        })
        .catch((error) => (document.getElementById(vid).innerHTML = error));
    },
     getKeywordStats(keywordTextDivi, vid, keywords_array) {
      // console.log(keywords_array[0].name);
      //console.log(keywords_array.length);

      for (var i = 0; i < keywords_array.length; i++) {
        this.comma_seperated_keywords.push(keywords_array[i].name);
      }
      console.log(this.comma_seperated_keywords);

      document.getElementById(vid).innerHTML = "Fetching...";
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch(
        `https://varilytics.com/spaceseat/gads/get_ads.php?keywords=${this.comma_seperated_keywords}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          document.getElementById(keywordTextDivi).innerHTML = result;
          document.getElementById(vid).innerHTML = "Fetch Stats";
        });
    },
    getKeywordStatsModal(k, keywords_array) {
      // console.log(keywords_array[0].name);
      //console.log(keywords_array.length);

      for (var i = 0; i < keywords_array.length; i++) {
        this.comma_seperated_keywords.push(keywords_array[i].name);
      }
      console.log(this.comma_seperated_keywords);

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      fetch(
        `https://varilytics.com/spaceseat/gads/get_ads.php?keywords=${this.comma_seperated_keywords}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          document.getElementById("stats-modal-content" + k).innerHTML = result;
          // document.getElementById("stats-modal-close" + k).className =
          // "visible text-center bg-gray-100 bg-opacity-50 sticky bottom-0";
        });
    },
      showImage(url) {
      this.$viewerApi({
        images: [url],
      });
    },
  },
  mounted() {
    this.fetchEvents();
  },
};
</script>
